/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3",
    h4: "h4",
    hr: "hr"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-8-treetop-tree-house"
  }, "Day 8: Treetop Tree House"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2022/day/8"
  }, "https://adventofcode.com/2022/day/8")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2022/src/day_08.rs"
  }, "my solution in\nRust"))), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The explained solution is based on (and almost identical to) ", React.createElement(_components.a, {
    href: "https://github.com/quantum-booty/advent-of-code-2022/blob/main/day08/src/lib.rs"
  }, "Henry’s solution"), "."), React.createElement(_components.p, null, "Thank you Henry!")), "\n", React.createElement(_components.p, null, "The expedition visits a perfectly square grid of trees the elves planted on a previous visit.\nThe elves would like to build a treehouse in one of the trees."), "\n", React.createElement(_components.p, null, "They sent up a drone to measure the height of each tree, expressed in a single digit: 0-9."), "\n", React.createElement(_components.p, null, "Your input is the height of every tree in the grid."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "30373\n25512\n65332\n33549\n35390\n")), "\n", React.createElement(_components.h2, {
    id: "parsing-the-input"
  }, "Parsing the input"), "\n", React.createElement(_components.p, null, "Turn that input into a 2 dimensional list."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Each item is a row."), "\n", React.createElement(_components.li, null, "Each row is a list of digits."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "fn parse() -> Vec<Vec<u32>> {\n    let input = std::fs::read_to_string(\"src/day08.txt\").unwrap();\n\n    input\n        .lines()\n        .map(|line| line.chars().map(|c| c.to_digit(10).unwrap()).collect())\n        .collect()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The question asks to count the number of trees that are visible from outside the grid when looking directly along a row or column."), "\n", React.createElement(_components.p, null, "A tree is visible if all of the other trees between it and an edge of the grid are shorter than it."), "\n", React.createElement(_components.p, null, "Only consider trees in the same row or column.\nThat means you can only look in 4 directions from each tree: up, down, left, and right."), "\n", React.createElement(_components.p, null, "All of the trees around the edge of the grid are visible."), "\n", React.createElement(_components.p, null, "The goal is to determine the visibilty of every tree in the grid.\nIf a tree is visible from multiple directions, only count it once."), "\n", React.createElement(_components.p, null, "Then count the amount of visible trees."), "\n", React.createElement(_components.p, null, "In pseudocode, that would be:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "pseudocode.rs"
  }, "all_trees\n    .map(/* determine visibility of this tree */)\n    .filter(/* keep visible trees */)\n    .count()\n")), "\n", React.createElement(_components.p, null, "The interesting part is the step where we figure out if a tree is visible (from any direction)."), "\n", React.createElement(_components.p, null, "Loop through every set of indexes in the grid. (the height of a tree is found by indexing into our parsed input: ", React.createElement(_components.code, null, "grid[row_idx][col_idx]"), ")"), "\n", React.createElement(_components.p, null, "Because all the trees along the edges are visible, skip those.\nAt the end of the loop, add the amount of trees along the 4 edges to the amount of visible trees you just calculated."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "A neat way to loop through all indexes in a grid is ", React.createElement(_components.a, {
    href: "https://docs.rs/itertools/latest/itertools/trait.Itertools.html#method.cartesian_product"
  }, "the ", React.createElement(_components.code, null, "cartesian_product"), " method"), ".\nIt’s the same thing as doing a manual double for loop."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "for row_idx in 0..len {\n    for col_idx in 0..len {\n        // your logic\n    }\n}\n"))), "\n", React.createElement(_components.p, null, "The skeleton code now looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "use itertools::Itertools;\n\nlet grid = parse();\nlet len = grid.len();\n\n// loop through coordinates of all trees that are not at an edge\n(1..len - 1)\n    .cartesian_product(1..len - 1)\n    .map(|(y, x)| {\n        // Is this tree visible from any direction? true/false\n        todo!();\n    })\n    .filter(|visible| *visible)\n    .count()\n    // add number of trees at edges, they are all visible\n    + (len - 1) * 4\n")), "\n", React.createElement(_components.p, null, "The most important sentence for part1: ", React.createElement(_components.strong, null, "A tree is visible if all of the other trees between it and an edge of the grid are shorter than it"), "."), "\n", React.createElement(_components.h3, {
    id: "a-helper-function"
  }, "A helper function"), "\n", React.createElement(_components.p, null, "For a given pair of indexes, this function returns a 4 lists."), "\n", React.createElement(_components.p, null, "Each list holds the remaining trees in one direction of the grid (up, down, left, right),\nfrom the perspective of the tree at those indexes."), "\n", React.createElement(_components.h4, {
    id: "implementation"
  }, "Implementation"), "\n", React.createElement(_components.p, null, "Extract the current ", React.createElement(_components.code, null, "row"), " and ", React.createElement(_components.code, null, "column"), " from the grid for a set of indexes."), "\n", React.createElement(_components.p, null, "Split those variables in 2 based on the ", React.createElement(_components.code, null, "col"), ", and ", React.createElement(_components.code, null, "row"), " index respectively."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The split ", React.createElement(_components.code, null, "column"), " returns the trees to the ", React.createElement(_components.code, null, "top"), ", and to the ", React.createElement(_components.code, null, "bottom"), " of the ", React.createElement(_components.code, null, "row"), " index."), "\n", React.createElement(_components.li, null, "The split ", React.createElement(_components.code, null, "row"), " returns the trees to the ", React.createElement(_components.code, null, "left"), ", and to the ", React.createElement(_components.code, null, "right"), " of the ", React.createElement(_components.code, null, "col"), " index."), "\n"), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "Confusing ", React.createElement(_components.code, null, "row"), " and ", React.createElement(_components.code, null, "col"), " is a very common thing to do when indexing into a rectangular grid")), "\n", React.createElement(_components.p, null, "Because this function returns the trees visible from the perspective of the tree at that pair of indexes,\nthe the ", React.createElement(_components.code, null, "up"), ", and the ", React.createElement(_components.code, null, "left"), " lists need to be reversed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "fn directions(grid: &[Vec<u32>], x: usize, y: usize) -> [Vec<u32>; 4] {\n    let row = grid[y].clone();\n    let column = grid.iter().map(|row| row[x]).collect::<Vec<u32>>();\n\n    let (up, down) = column.split_at(y);\n    let (left, right) = row.split_at(x);\n\n    let up = up.iter().copied().rev().collect();\n    let left = left.iter().copied().rev().collect();\n    let right = right[1..].to_vec();\n    let down = down[1..].to_vec();\n\n    [up, down, left, right]\n}\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Back to the main solution."), "\n", React.createElement(_components.p, null, "Updated skeleton code:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "use itertools::Itertools;\n\nlet grid = parse();\nlet len = grid.len();\n\n(1..len - 1)\n    .cartesian_product(1..len - 1)\n    .map(|(y, x)| {\n        directions(&grid, x, y)\n            .iter()\n            // is the tree at x,y visible from this direction: true/false\n            .map(|direction| { todo!(); })\n            // count a tree that is visible from multiple directions only once\n            .any(|visible| visible)\n    })\n    .filter(|visible| *visible)\n    .count()\n    + (len - 1) * 4\n")), "\n", React.createElement(_components.p, null, "And that “most important sentence” is the way we figure out if a tree is visible."), "\n", React.createElement(_components.p, null, "Reminder of that sentence: ", React.createElement(_components.strong, null, "A tree is visible if all of the other trees between it and an edge of the grid are shorter than it"), "."), "\n", React.createElement(_components.h3, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "use itertools::Itertools;\n\nfn parse() -> Vec<Vec<u32>> {\n    let input = std::fs::read_to_string(\"src/day08.txt\").unwrap();\n\n    input\n        .lines()\n        .map(|line| line.chars().map(|c| c.to_digit(10).unwrap()).collect())\n        .collect()\n}\n\nfn directions(grid: &[Vec<u32>], x: usize, y: usize) -> [Vec<u32>; 4] {\n    let row = grid[y].clone();\n    let column = grid.iter().map(|row| row[x]).collect::<Vec<u32>>();\n\n    let (up, down) = column.split_at(y);\n    let (left, right) = row.split_at(x);\n\n    let up = up.iter().copied().rev().collect();\n    let left = left.iter().copied().rev().collect();\n    let right = right[1..].to_vec();\n    let down = down[1..].to_vec();\n\n    [up, down, left, right]\n}\n\npub fn part_1() -> usize {\n    let trees = parse();\n    let len = trees.len();\n\n    (1..len - 1)\n        .cartesian_product(1..len - 1)\n        .map(|(y, x)| {\n            let height = trees[y][x];\n            directions(&trees, x, y)\n                .iter()\n                .map(|direction| direction.iter().all(|h| *h < height))\n                .any(|visible| visible)\n        })\n        .filter(|visible| *visible)\n        .count()\n        + (len - 1) * 4\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "The elves would like to be able to see a lot of trees from their treehouse."), "\n", React.createElement(_components.p, null, "The question asks what the highest ", React.createElement(_components.strong, null, "scenic score"), " possible is."), "\n", React.createElement(_components.p, null, "A scenic score is a way to express how good the viewing distance in each direction is."), "\n", React.createElement(_components.p, null, "It’s the product of the amount of trees that are visible in the 4 directions."), "\n", React.createElement(_components.p, null, "To measure the viewing distance from a given tree, look up, down, left, and right from that tree;\nstop if you reach an edge or at the first tree that is the same height or taller than the tree under consideration.\n(If a tree is right on the edge, at least one of its viewing distances will be zero.)"), "\n", React.createElement(_components.p, null, "The goal is to calculate a scenic score for every tree, and find that maximum of those scores."), "\n", React.createElement(_components.p, null, "In pseudocode that would be:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "pseudocode.rs"
  }, "all_trees\n    .map(/* calculate scenic score */)\n    .max()\n    .unwrap()\n")), "\n", React.createElement(_components.p, null, "The main setup for part2 is the same as part1."), "\n", React.createElement(_components.p, null, "The interesting part is inside that ", React.createElement(_components.code, null, ".map"), " where we convert a pair of indexes to a scenic score."), "\n", React.createElement(_components.p, null, "For a given pair of indexes, that map first determines how many trees are visible in every direction."), "\n", React.createElement(_components.p, null, "Then it takes the product of those 4 numbers to get the tree’s scenic score."), "\n", React.createElement(_components.p, null, "In skeleton code:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "use itertools::Itertools;\n\nlet grid = parse();\nlet len = grid.len();\n\n(1..len - 1)\n    .cartesian_product(1..len - 1)\n    .map(|(y, x)| {\n        directions(&grid, x, y)\n            .iter()\n            // how many trees are visible in this direction?\n            .map(|direction| { todo!(); })\n            // multiply the results of each direction to calculate the scenic score\n            .product()\n    })\n    .max()\n    .unwrap()\n")), "\n", React.createElement(_components.p, null, "The most important sentence in the question for part2: ", React.createElement(_components.strong, null, "To measure the viewing distance from a given tree, look up, down, left, and right from that tree; stop if you reach an edge or at the first tree that is the same height or taller than the tree under consideration.")), "\n", React.createElement(_components.p, null, "And that “most important sentence” is the way we figure out how many trees are visible in a direction."), "\n", React.createElement(_components.h3, {
    id: "final-code-1"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_08.rs"
  }, "use itertools::Itertools;\n\nfn parse() -> Vec<Vec<u32>> {\n    let input = std::fs::read_to_string(\"src/day08.txt\").unwrap();\n\n    input\n        .lines()\n        .map(|line| line.chars().map(|c| c.to_digit(10).unwrap()).collect())\n        .collect()\n}\n\nfn directions(grid: &[Vec<u32>], x: usize, y: usize) -> [Vec<u32>; 4] {\n    let row = grid[y].clone();\n    let column = grid.iter().map(|row| row[x]).collect::<Vec<u32>>();\n\n    let (up, down) = column.split_at(y);\n    let (left, right) = row.split_at(x);\n\n    let up = up.iter().copied().rev().collect();\n    let left = left.iter().copied().rev().collect();\n    let right = right[1..].to_vec();\n    let down = down[1..].to_vec();\n\n    [up, down, left, right]\n}\n\npub fn part_2() -> usize {\n    let trees = parse();\n    let len = trees.len();\n\n    (1..len - 1)\n        .cartesian_product(1..len - 1)\n        .map(|(y, x)| {\n            let height = trees[y][x];\n            directions(&trees, x, y)\n                .iter()\n                .map(|direction| {\n                    direction\n                        .iter()\n                        .position(|h| *h >= height)\n                        .map(|p| p + 1)\n                        .unwrap_or_else(|| direction.len())\n                })\n                .product::<usize>()\n        })\n        .max()\n        .unwrap()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
